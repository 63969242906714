import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import { Image, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { CardHeader } from "@mui/material";
import ReportPageAccordian from "../accordians/ReportPageAccordians";
import MenuAccordian from "../accordians/MenuAccordian";
import "./sidebar.scss";
import {
  ADMIN_MENU,
  CLIENT_ENGAGEMENT_MENU,
  RECHARTS_MENU,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REPORT_LIST,
  ADMIN_POST_FILTERTYPE,
  ADMIN_POST_FILTERVALUES,
  ADMIN_UPDATE_REPORT_GROUP,
  ADMIN_POST_REPORT_GROUP,
  ADMIN_POST_USERACCOUNT,
  ADMIN_USER_MONITOR,
  TICKET_OVERVIEW,
  PROPHET_LOGS,
  ATOM_TICKETS,
  PROJECT_TICKETS,
  ZOHO_TICKETS,
  ZOHO_TICKET_MENU,
  PLANNING_MENU,
  WORK_REQUEST,
} from "../../utils/types";
import CyclingLogosSideBar from "../logoSwitcher/CyclingLogosSidebar";
import CyclingLogosSideBarDark from "../logoSwitcher/CyclingLogosSidebarDark";
import { BulletList } from "react-content-loader";
import { useEffect } from "react";
import { getUserDashboardList } from "slice/recharts";
import { fetchUserPageList } from "slice/planning";
const Sidebar = () => {
  const { isToggled } = useSelector((state) => state.isSidebarToggled);
  const darkMode = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();
  const { userRechartDashboardsLoading, userRechartDashboards } = useSelector(
    (state) => state.rechartDashboards
  );

  const { userPlanningPagesLoading, userPlanningPages } = useSelector(
    (state) => state.planning
  );

  const { reportListLoading, reportPagesLoading, reportPages } = useSelector(
    (state) => state.pbiReducer
  );
  const [dashboardList, setDashboardList] = useState([]);
  const [planningPageList, setPlanningPageList] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { user, subscriptions, ticketsLoading } = useSelector(
    (state) => state.userInfo
  );

  const companyname = user === null ? "Atom DTS" : user.customer_name;
  const companylogoUrl =
    user === null ? "logos/Atom_DTS.png" : user.customerLogoName;

  const companyLogoLight =
    user === null || !user || user.customerLogoLight === undefined
      ? undefined
      : user.customerLogoLight;
  const companyLogoDark =
    user === null || !user || user.customerLogoDark === undefined
      ? undefined
      : user.customerLogoDark;

  const userIstaff = user === null ? false : user.is_staff;

  const pbi_sub = subscriptions ? subscriptions.hasOwnProperty("pbi") : false;
  const cep_sub = subscriptions ? subscriptions.hasOwnProperty("cep") : false;
  const ztp_sub = subscriptions ? subscriptions.hasOwnProperty("ztp") : false;
  const rec_sub = subscriptions ? subscriptions.hasOwnProperty("rec") : false;
  const ppa_sub = subscriptions ? subscriptions.hasOwnProperty("ppa") : false;

  // RECHART SUBSCRIPTION
  useEffect(() => {
    if (rec_sub) {
      dispatch(getUserDashboardList());
    }
  }, [rec_sub]);

  useEffect(() => {
    if (userRechartDashboards.length > 0) {
      let dashboardListTemp;
      dashboardListTemp = userRechartDashboards.map((item) => ({
        name: item.id,
        description: item.description,
        default_sort: item.default_sort,
      }));

      dashboardListTemp.sort((a, b) => {
        return a.default_sort - b.default_sort;
      });
      setDashboardList(dashboardListTemp);
    }
  }, [userRechartDashboardsLoading, userRechartDashboards]);

  // PLANNNING SUBSCRIPTION
  useEffect(() => {
    if (ppa_sub) {
      dispatch(fetchUserPageList());
    }
  }, [ppa_sub]);

  useEffect(() => {
    if (userPlanningPages && userPlanningPages.length > 0) {
      let pageListTemp;
      pageListTemp = userPlanningPages.map((item) => ({
        name: item.id,
        description: item.description,
        default_sort: item.default_sort,
      }));

      pageListTemp.sort((a, b) => {
        return a.default_sort - b.default_sort;
      });

      setPlanningPageList(pageListTemp);
    }
  }, [userPlanningPagesLoading, userPlanningPages]);

  const imageLocation =
    process.env.REACT_APP_STORAGE_URL + "/" + companylogoUrl;
  const engagmentItems = [
    {
      name: TICKET_OVERVIEW,
      description: TICKET_OVERVIEW,
    },
    {
      name: PROPHET_LOGS,
      description: PROPHET_LOGS,
    },
    {
      name: ATOM_TICKETS,
      description: ATOM_TICKETS,
    },
    {
      name: PROJECT_TICKETS,
      description: PROJECT_TICKETS,
    },
    {
      name: WORK_REQUEST,
      description: WORK_REQUEST,
    },
  ];

  const zohoTicketItems = [
    {
      name: TICKET_OVERVIEW,
      description: TICKET_OVERVIEW,
    },
    {
      name: ZOHO_TICKETS,
      description: ZOHO_TICKETS,
    },
  ];

  const adminItems = [
    { name: ADMIN_REPORT_LIST, description: ADMIN_REPORT_LIST },
    { name: ADMIN_REGISTER_REQUEST, description: ADMIN_REGISTER_REQUEST },
    { name: ADMIN_POST_FILTERTYPE, description: ADMIN_POST_FILTERTYPE },
    { name: ADMIN_POST_FILTERVALUES, description: ADMIN_POST_FILTERVALUES },
    { name: ADMIN_UPDATE_REPORT_GROUP, description: ADMIN_UPDATE_REPORT_GROUP },
    { name: ADMIN_POST_REPORT_GROUP, description: ADMIN_POST_REPORT_GROUP },
    { name: ADMIN_POST_USERACCOUNT, description: ADMIN_POST_USERACCOUNT },
    { name: ADMIN_USER_MONITOR, description: ADMIN_USER_MONITOR },
    // { name: LOG_REQUEST, description: LOG_REQUEST},
  ];

  return isToggled === true && isMobile === false ? (
    <div className="sidebar">
      <div className="topbar">
        <CardHeader
          avatar={
            <Image
              roundedCircle
              alt="company avatar"
              src={imageLocation}
              width="50"
              height="50"
              className="topbar-list"
            />
          }
          title={companyname}
          classes={{
            title: "company",
            avatar: "avatar",
          }}
        />
      </div>
      <Row className="justify-content-md-center">
        <hr />
      </Row>

      <div className="center">
        {pbi_sub ? (
          <>
            {reportPagesLoading && !reportPages ? (
              <>
                <BulletList />
                <BulletList />
              </>
            ) : (
              <ul>
                <ReportPageAccordian />
              </ul>
            )}
          </>
        ) : (
          <></>
        )}

        {rec_sub ? (
          <>
            {/* {ticketsLoading ? (
              <BulletList />
            ) : ( */}
            <ul>
              <MenuAccordian
                sectionName="Dashboards"
                pagedict={dashboardList}
                itemTypes={RECHARTS_MENU}
              />
            </ul>
            {/* )} */}
          </>
        ) : (
          <></>
        )}
        {ppa_sub ? (
          <>
            {/* {ticketsLoading ? (
              <BulletList />
            ) : ( */}
            <ul>
              <MenuAccordian
                sectionName="Beplanning"
                pagedict={planningPageList}
                itemTypes={PLANNING_MENU}
              />
            </ul>
            {/* )} */}
          </>
        ) : (
          <></>
        )}

        {cep_sub ? (
          <>
            {/* {ticketsLoading ? (
              <BulletList />
            ) : ( */}
            <ul>
              <MenuAccordian
                sectionName="Client Engagement"
                pagedict={engagmentItems}
                itemTypes={CLIENT_ENGAGEMENT_MENU}
              />
            </ul>
            {/* )} */}
          </>
        ) : (
          <></>
        )}

        {ztp_sub ? (
          <>
            {ticketsLoading ? (
              <BulletList />
            ) : (
              <ul>
                <MenuAccordian
                  sectionName="Zoho Tickets"
                  pagedict={zohoTicketItems}
                  itemTypes={ZOHO_TICKET_MENU}
                />
              </ul>
            )}
          </>
        ) : (
          <></>
        )}

        {userIstaff ? (
          <ul>
            <MenuAccordian
              sectionName="Admin"
              pagedict={adminItems}
              itemTypes={ADMIN_MENU}
            />
          </ul>
        ) : (
          <></>
        )}
      </div>

      <div className=" bottomHide">
        <Row className="justify-content-md-center">
          <hr />
          <Col lg="auto">
            {/* <img src={Logo} width="150px" className="mb-3" alt="logo" /> */}
            {companyLogoLight && companyLogoDark ? (
              <div>
                {darkMode ? (
                  <Image alt="" src={companyLogoDark} width="150px" />
                ) : (
                  <Image alt="" src={companyLogoLight} width="150" />
                )}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </div>
  ) : isMobile === true && isToggled === true ? (
    <Menu width={"240px"} isOpen={isToggled}>
      <div className="sidebar">
        <div className="topbar">
          <li className="topbar-list">
            <Image
              roundedCircle
              alt=""
              src={imageLocation}
              width="50"
              height="50"
              className="image-logo"
            />
            <span>
              <div className="company">{companyname}</div>
            </span>
          </li>
        </div>
        <Row className="justify-content-center">
          <hr />
        </Row>
        <div className="center">
          {pbi_sub ? (
            <>
              {reportListLoading || reportPagesLoading ? (
                <>
                  <BulletList />
                  <BulletList />
                </>
              ) : (
                <ul>
                  <ReportPageAccordian />
                </ul>
              )}
            </>
          ) : (
            <></>
          )}

          {cep_sub ? (
            <>
              {ticketsLoading ? (
                <BulletList />
              ) : (
                <ul>
                  <MenuAccordian
                    sectionName="Client Engagement"
                    pagedict={engagmentItems}
                    itemTypes={CLIENT_ENGAGEMENT_MENU}
                  />
                </ul>
              )}
            </>
          ) : (
            <></>
          )}
          {ppa_sub ? (
            <>
              {/* {ticketsLoading ? (
              <BulletList />
            ) : ( */}
              <ul>
                <MenuAccordian
                  sectionName="Beplanning"
                  pagedict={planningPageList}
                  itemTypes={PLANNING_MENU}
                />
              </ul>
              {/* )} */}
            </>
          ) : (
            <></>
          )}
          {userIstaff ? (
            <ul>
              <MenuAccordian
                sectionName="Admin"
                pagedict={adminItems}
                itemTypes={ADMIN_MENU}
              />
            </ul>
          ) : (
            <></>
          )}
        </div>

        <div className=" bottomHide">
          <Row className="justify-content-center">
            <hr />
            <Col lg="auto" xs="auto">
              {/* <img src={Logo} width="150px" className="mb-3" alt="logo" /> */}
              {companyLogoLight && companyLogoDark ? (
                <div>
                  {darkMode ? (
                    <Image alt="" src={companyLogoDark} width="150px" />
                  ) : (
                    <Image alt="" src={companyLogoLight} width="150" />
                  )}
                </div>
              ) : (
                <>
                  {darkMode ? (
                    <CyclingLogosSideBarDark />
                  ) : (
                    <CyclingLogosSideBar />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Menu>
  ) : (
    <></>
  );
};

export default Sidebar;
