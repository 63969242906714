
export const SELECT_REPORT = "SELECT_REPORT";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_EMBED_INFO = "FETCH_EMBED_INFO";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const FETCH_USER_REPORTS = "FETCH_USER_REPORTS"; 
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_THEME = "SET_THEME";
export const DARK_MODE = "DARK_MODE";

export const FETCH_REPORT_PAGES = "FETCH_REPORT_PAGES";


export const ADMIN_MENU = "ADMIN_MENU";
export const CLIENT_ENGAGEMENT_MENU = "CLIENT_ENGAGEMENT_MENU";
export const RECHARTS_MENU = "RECHARTS_MENU";
export const ZOHO_TICKET_MENU = "ZOHO_TICKET_MENU";
export const PBI_MENU = "PBI_MENU";
export const PLANNING_MENU = "PLANNING_MENU";
export const SANDBOX_MENU = "SANDBOX_MENU";

export const ADMIN_REPORT_LIST = "Report Status List"
export const ADMIN_REGISTER_REQUEST =  "Register Request"
export const ADMIN_POST_FILTERTYPE = "Create Filter Type"
export const ADMIN_POST_FILTERVALUES = "Create Filter Values"
export const ADMIN_POST_REPORT_GROUP = "Create Report Group"
export const ADMIN_UPDATE_REPORT_GROUP = "Update Report Group"
export const ADMIN_POST_USERACCOUNT = "Edit User Account"
export const ADMIN_LANDING_PAGE = "Landing Page Concept"
export const ADMIN_USER_MONITOR = "User Monitor"

export const DEPARTMENT_NAME = "Department_Name";
export const PROJECT_NAME = "Project_Name";
export const PRIORITY_NAME = "daysOverdueCategory"
export const ORGINIZATION_NAME = "organization";
export const TICKET_STATUS = "Log_or_Ticket_Status";
export const TICKET_PRIORITY = "Log_or_Ticket_Priority";
export const TICKET_NUMBER = "Log_or_Ticket_Number";
export const TAXONOMY = "Taxonomy";
export const IS_OVERDUE = "Is_Overdue";
export const PARTNER_REFERENCE = "Partner_Reference"; 

export const TICKET_OVERVIEW = "Work Request Summary"; 
export const PROPHET_LOGS = "Prophet Logs"; 
export const ATOM_TICKETS = "Atom Tickets"; 
export const PROJECT_TICKETS = "Projects"; 
export const ZOHO_TICKETS = "Zoho Tickets"; 
export const RELEASE_NOTES = 'Release Notes';
export const WORK_REQUEST = 'Work Request';

export const SANDBOX_TICKETS = "Tickets Overview"; 
export const SANDBOX_LANDING = "Landing Overview"; 
export const SANDBOX_ADMIN = "Filter Type Form"; 
export const SANDBOX_USER_MONITOR = "User Monitor"; 
export const SANDBOX_DESIGN = "Design Update"; 


export const CELLAR_PLANNING = "Week Beplanning"
export const CELLAR_PLANNING_REPORT = "Week Opsomming"
export const PRODUCER_PLANNING = "Pars Beplanning"
export const PRODUCER_PLANNING_REPORT = "Pars Opsomming"