import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeAuthenticatedApiCall, makeAuthenticatedApiCall_formData } from "hubl/utils/helpers";

export const getCustomerList = createAsyncThunk(
  "api/list/customers",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/list/customers/", "GET",
      null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerUserList = createAsyncThunk(
  "api/user/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/user/list", "GET",
      null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchFilterValueList = createAsyncThunk(
  "api/filtervalue/list",
  async (form_data, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/filtervalue/list/", "POST",
      form_data, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getFilterTypeList = createAsyncThunk(
  "api/filtertype/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/filtertype/list", "GET",
      null, thunkAPI);

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getReportGroupList = createAsyncThunk(
  "api/reportgroup/list",
  async (customer_id, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(`api/reportgroup/list/${customer_id}`, "GET",
      null, thunkAPI);
      
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAdditionalUserInfo = createAsyncThunk(
  "api/user/info/additional",
  async (_, thunkAPI) => {

    try {
      const res = await makeAuthenticatedApiCall("api/user/info/additional", "GET",
      null, thunkAPI);
      
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getSelectedUserInfo = createAsyncThunk(
  "api/user/info",
  async (form_data, thunkAPI) => {

    
    try {
      const res = await makeAuthenticatedApiCall("api/user/info/", "POST",
      { user_id: form_data }, thunkAPI);

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postFilterType = createAsyncThunk(
  "api/filtertype/post",
  async (form_data, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/filtertype/post/", "POST",
      form_data, thunkAPI);
      const data = await res.json();
      if (res.status === 201) {
        toast.success("Filter type posted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Unable to post filter type", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postFilterValues = createAsyncThunk(
  "api/filtervalue/post",
  async (form_data, thunkAPI) => {

    try {
      const res = await makeAuthenticatedApiCall("api/filtervalue/post/", "POST",
      form_data, thunkAPI);
      const data = await res.json();
      if (res.status === 201) {
        toast.success("Filter values posted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Unable to post filter values", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const posUserAccountUpdate = createAsyncThunk(
  "api/useraccount/update",
  async (form_data, thunkAPI) => {
    try {

      const res = await makeAuthenticatedApiCall("api/useraccount/update/", "POST",
      form_data, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        toast.success("User account updated", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Unable to update user account", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err.response.data, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerActiveReports = createAsyncThunk(
  "api/customer/active/reports/list",
  async (customer_id, thunkAPI) => {

    try {
      const res = await makeAuthenticatedApiCall(`api/customer/active/reports/list/${customer_id}`, "GET",
      null, thunkAPI);
      
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getPriorityList = createAsyncThunk(
  // TODO
  "api/list/customers",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/list/customers/", "GET",
      null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCategoryList = createAsyncThunk(
  // TODO
  "api/list/customers",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/list/customers/", "GET",
      null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getProductList = [
  {
    label: "QX",
    value: "QX",
  },
  {
    label: "PR4",
    value: "PR4",
  },
  {
    label: "HUBL",
    value: "HUBL",
  },
];

// createAsyncThunk(
//   // TODO
//   "api/list/customers",
//   async (_, thunkAPI) => {
//     try {
//       const res = await makeAuthenticatedApiCall("api/list/customers/", "GET",
//       null, thunkAPI);
//       const data = await res.json();
//       if (res.status === 200) {
//         return data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.response.data);
//     }
//   }
// );

export const postWorkRequest = createAsyncThunk(
  "api/workrequest/post",
  async (form_data, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall_formData("api/workrequest/post/", "POST",
      form_data, thunkAPI, true);

      const data = await res.json();
      if (res.status === 201) {
        toast.success("New work request posted - #" + data.request_id, {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // if (data.url) {
        //   window.open(data.url , '_blank', 'noopener,noreferrer')
        // }
        return data;
      } else {
        var errors = Object.keys(data);
        var msg = "Unable to post new work request";

        if (errors.length > 0) {
          msg = "Unable to post new work request - Field not captured : " + errors[0]
        }
        
        toast.error(msg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postReportGroup = createAsyncThunk(
  "api/reportgroup/post",
  async (form_data, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/reportgroup/post/", "POST",
      form_data, thunkAPI);

      const data = await res.json();
      if (res.status === 201) {
        toast.success("New report group posted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Unable to post new report group", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserGroupAll = createAsyncThunk(
  "pbi/fetchUserGroupAll",
  async ({ report_group_id, customer_id }, thunkAPI) => {

    const body = {
      report_group_id: report_group_id,
      customer_id: customer_id,
    }

    try {
      const res = await makeAuthenticatedApiCall("api/usergroup/list/", "POST",
      body, thunkAPI);

      const data = await res.json();
      if (res.status === 200) {
        return data.reports;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

const initialState = {
  customerList: null,
  customerListLoading: false,
  customerUserList: null,
  customerUserListLoading: false,
  customerActiveReports: null,
  customerActiveReportsLoading: false,

  filterTypeList: null,
  filterTypeListLoading: false,
  filterTypePostLoading: false,
  filterValueList: null,
  filterValueListLoading: false,

  reportGroupList: null,
  reportGroupListLoading: false,
  filterValuesPostLoading: false,
  reportGroupPostLoading: false,

  additionalUserInfo: null,
  additionalUserInfoLoading: false,

  selectedUserInfo: null,
  selectedUserInfoLoading: false,

  userGroupReport: [],
  userGroupReportLoading: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetRegistered: (state) => {
      state.registered = false;
    },
    updateUserGroup: (state, action) => {
      const { report_id } = action.payload;
      const updatedState = state.userGroupReport.map((obj) => {
        if (obj.report_id === report_id) {
          return {
            ...obj,
            is_selected: obj.is_selected === 1 ? 0 : 1,
          };
        }
        return obj;
      });

      return {
        ...state,
        userGroupReport: updatedState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.pending, (state) => {
        state.customerListLoading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.customerListLoading = false;
        state.customerList = action.payload;
      })
      .addCase(getCustomerList.rejected, (state) => {
        state.customerListLoading = true;
      })

      .addCase(getCustomerUserList.pending, (state) => {
        state.customerUserListLoading = true;
      })
      .addCase(getCustomerUserList.fulfilled, (state, action) => {
        state.customerUserListLoading = false;
        state.customerUserList = action.payload;
      })
      .addCase(getCustomerUserList.rejected, (state) => {
        state.customerUserListLoading = true;
      })

      .addCase(fetchFilterValueList.pending, (state) => {
        state.filterValueListLoading = true;
      })
      .addCase(fetchFilterValueList.fulfilled, (state, action) => {
        state.filterValueListLoading = false;
        state.filterValueList = action.payload;
      })
      .addCase(fetchFilterValueList.rejected, (state) => {
        state.filterValueListLoading = true;
      })

      .addCase(getReportGroupList.pending, (state) => {
        state.reportGroupListLoading = true;
      })
      .addCase(getReportGroupList.fulfilled, (state, action) => {
        state.reportGroupListLoading = false;
        state.reportGroupList = action.payload;
      })
      .addCase(getReportGroupList.rejected, (state) => {
        state.reportGroupListLoading = true;
      })

      .addCase(getCustomerActiveReports.pending, (state) => {
        state.customerActiveReportsLoading = true;
      })
      .addCase(getCustomerActiveReports.fulfilled, (state, action) => {
        state.customerActiveReportsLoading = false;
        state.customerActiveReports = action.payload;
      })
      .addCase(getCustomerActiveReports.rejected, (state) => {
        state.customerActiveReportsLoading = true;
      })

      .addCase(fetchUserGroupAll.pending, (state) => {
        state.userGroupReportLoading = true;
      })
      .addCase(fetchUserGroupAll.fulfilled, (state, action) => {
        state.userGroupReportLoading = false;
        state.userGroupReport = action.payload;
      })
      .addCase(fetchUserGroupAll.rejected, (state) => {
        state.userGroupReportLoading = true;
      })

      .addCase(postFilterType.pending, (state) => {
        state.filterTypePostLoading = true;
      })
      .addCase(postFilterType.fulfilled, (state) => {
        state.filterTypePostLoading = false;
      })
      .addCase(postFilterType.rejected, (state) => {
        state.filterTypePostLoading = true;
      })

      .addCase(getAdditionalUserInfo.pending, (state) => {
        state.additionalUserInfoLoading = true;
      })
      .addCase(getAdditionalUserInfo.fulfilled, (state, action) => {
        state.additionalUserInfoLoading = false;
        state.additionalUserInfo = action.payload;
      })
      .addCase(getAdditionalUserInfo.rejected, (state) => {
        state.additionalUserInfoLoading = true;
      })

      .addCase(getSelectedUserInfo.pending, (state) => {
        state.selectedUserInfoLoading = true;
      })
      .addCase(getSelectedUserInfo.fulfilled, (state, action) => {
        state.selectedUserInfoLoading = false;
        state.selectedUserInfo = action.payload;
      })
      .addCase(getSelectedUserInfo.rejected, (state) => {
        state.selectedUserInfoLoading = true;
      })

      .addCase(getFilterTypeList.pending, (state) => {
        state.filterTypeListLoading = true;
      })
      .addCase(getFilterTypeList.fulfilled, (state, action) => {
        state.filterTypeListLoading = false;
        state.filterTypeList = action.payload;
      })
      .addCase(getFilterTypeList.rejected, (state) => {
        state.filterTypeListLoading = true;
      })

      .addCase(postFilterValues.pending, (state) => {
        state.filterValuesPostLoading = true;
      })
      .addCase(postFilterValues.fulfilled, (state) => {
        state.filterValuesPostLoading = false;
      })
      .addCase(postFilterValues.rejected, (state) => {
        state.filterValuesPostLoading = true;
      })

      .addCase(postReportGroup.pending, (state) => {
        state.reportGroupPostLoading = true;
      })
      .addCase(postReportGroup.fulfilled, (state) => {
        state.reportGroupPostLoading = false;
      })
      .addCase(postReportGroup.rejected, (state) => {
        state.reportGroupPostLoading = true;
      });
  },
});
export const { updateUserGroup } = adminSlice.actions;
export default adminSlice.reducer;
