import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "slice/user";
import { setDark } from "slice/theme";
import { toggleSidebar } from "slice/toggleSidebar";
import { getPbiTheme, getReportList, getExportReport } from "slice/pbi";
import { setReportConfig } from "slice/reportConfig";
import "./navbardash.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { selectDashboardSection } from "slice/dashboardSection";
import { persistor } from "../../../index";
import { Image } from "react-bootstrap";
import { RELEASE_NOTES, PBI_MENU } from "../../utils/types";
import HUBL_Default from "../../../assets/images/logo/HUBL_Default_Avatar.png";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "../../theme";
import { tokens } from "../../theme";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { exportPDF } from "../../utils/helpers";

export default function NavbarDash() {
  const { reportList, pbiThemeloading, pbiTheme, report_id } = useSelector(
    (state) => state.pbiReducer
  );
  const dispatch = useDispatch();
  const { unreadCount } = useSelector((state) => state.notifications);
  const { isToggled } = useSelector((state) => state.isSidebarToggled);
  const { user, subscriptions } = useSelector((state) => state.userInfo);
  const { section } = useSelector((state) => state.dashboardSection);
  const darkMode = useSelector((state) => state.darkMode);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);
  const [checked, setChecked] = useState(false);
  let { isAuthenticated, loading, tokenUpdateLoading } = useSelector(
    (state) => state.userInfo
  );
  const pbi_sub = subscriptions ? subscriptions.hasOwnProperty("pbi") : false;
  const companyAvatarLight =
    user === null ? HUBL_Default : user.customerAvatarLight;
  const companyAvatarDark =
    user === null ? HUBL_Default : user.customerAvatarDark;
  const inverseTheme = darkMode ? "LIGHT" : "DARK";
  const normalTheme = darkMode ? "DARK" : "LIGHT";

  const handleChange = () => {
    dispatch(setDark(inverseTheme));
    dispatch(getReportList());
  };

  useEffect(() => {
    if (reportList) {
      dispatch(getPbiTheme({ theme: normalTheme }));
    }
  }, [dispatch, reportList]);

  useEffect(() => {
    if (reportList && pbiTheme && !pbiThemeloading && pbi_sub) {
      if (reportList[report_id]) {
        const embed_url = reportList[report_id].embedUrl;
        dispatch(
          setReportConfig({
            embedUrl: embed_url,
            theme: { themeJson: pbiTheme.theme_json },
          })
        );
      }
    }
  }, [dispatch, pbiTheme]);

  useEffect(() => {
    if (darkMode) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [dispatch, darkMode]);

  const username =
    user === null ? "..." : `${user.first_name} ${user.last_name}`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#e3e3e3" : "#e3e3e3",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#ec5210" : "#ec5210",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#ec5210" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlRefreshClick = () => {
    if (isAuthenticated && !loading && !tokenUpdateLoading) {
      dispatch(getReportList());
    }
  };

  const handleExportClick = () => {
    if (isAuthenticated && !loading && !tokenUpdateLoading) {
      //dispatch(getReportList());
      dispatch(getExportReport());
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLink = (key) => {
    dispatch(selectDashboardSection(key));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ bgcolor: "white" }}
        className="navbarDash"
        elevation={0}
        position="static"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => dispatch(toggleSidebar(!isToggled))}
          >
            <MenuIcon className="icon" />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {
            <div>
              {section === PBI_MENU ? (
                <Tooltip title="Export Report">
                  <FileDownloadOutlinedIcon
                    sx={{ marginRight: "10px", color: colors.primary[300] }}
                    onClick={() => handleExportClick()}
                  />
                </Tooltip>
              ) : null}
              {section === PBI_MENU ? (              

                <Tooltip title="Refresh Reports">
                  <RefreshOutlinedIcon
                    sx={{ marginRight: "10px", color: colors.primary[300] }}
                    onClick={() => handlRefreshClick()}
                  />
                </Tooltip>
              ) : null}
              {/* <Tooltip title="Download PDF">
                <DownloadForOfflineOutlinedIcon
                  sx={{ marginRight: "10px", color: colors.primary[300] }}
                  onClick={exportPDF}
                />
              </Tooltip> */}
              <MaterialUISwitch checked={checked} onChange={handleChange} />

              <Tooltip title="Account settings">
                <Badge
                  badgeContent={unreadCount}
                  color="primary"
                  size="small"
                  sx={{ ".MuiBadge-badge": { top: 10, right: 10 } }}
                >
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {companyAvatarLight && companyAvatarDark ? (
                      <div>
                        {darkMode ? (
                          <Image
                            roundedCircle
                            alt="company avatar"
                            src={companyAvatarDark}
                            width="35"
                            height="35"
                          />
                        ) : (
                          <Image
                            roundedCircle
                            alt="company avatar"
                            src={companyAvatarLight}
                            width="35"
                            height="35"
                          />
                        )}
                      </div>
                    ) : (
                      <Avatar
                        sx={{ width: 35, height: 35, bgcolor: "#6a6a75" }}
                      ></Avatar>
                    )}
                  </IconButton>{" "}
                </Badge>
              </Tooltip>
              <Paper>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem>
                    <Avatar /> {username}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => onClickLink(RELEASE_NOTES)}>
                    <Badge
                      badgeContent={unreadCount}
                      color="primary"
                      size="small"
                      sx={{ ".MuiBadge-badge": { right: -10 } }}
                    >
                      <span
                        style={{
                          color: "grey",
                          marginRight: 10,
                        }}
                      >
                        <TextSnippetOutlinedIcon />
                      </span>
                      Release Notes{" "}
                    </Badge>
                  </MenuItem>

                  {/* <MenuItem>
                  <Avatar /> My account
                </MenuItem> */}
                  <Divider />
                  {/* <MenuItem onClick={() => navigate("/register")}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Register User
                </MenuItem>
                <MenuItem onClick={() => navigate("/usergroup")}>
                  <ListItemIcon>
                    <GroupAddIcon fontSize="small" />
                  </ListItemIcon>
                  Create User Group
                </MenuItem> */}
                  <MenuItem
                    onClick={() =>
                      dispatch(logout()).then(() => {
                        persistor.purge();
                        // dispatch(setDark("LIGHT"));
                      })
                    }
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Paper>
            </div>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
