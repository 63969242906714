import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";
import { setReportConfig } from "slice/reportConfig";
import { logout } from "./user";
import { toast } from "react-toastify";

export const getPbiTheme = createAsyncThunk(
  "pbi/theme",
  async ({ theme }, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        `api/theme?theme=${theme}`,
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserReportList = createAsyncThunk(
  "pbi/users/reports",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "api/users/reports",
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getExportReport = createAsyncThunk(
  "pbi/exportreportpages",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/exportreportpages",
        "POST",
        null,
        thunkAPI
      );
      const data = await res.json();

      const { dispatch } = thunkAPI;
      if (res.status === 202) {
        return thunkAPI.fulfillWithValue(data.reports);
      } else {
        dispatch(logout());
        toast.error("Unable to retrieve report list", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(JSON.stringify(err.response), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getReportList = createAsyncThunk(
  "pbi/list/reports",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/getreportlist",
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();

      const { dispatch } = thunkAPI;
      if (res.status === 200) {
        return thunkAPI.fulfillWithValue(data.reports);
      } else {
        dispatch(logout());
        toast.error("Unable to retrieve report list", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(JSON.stringify(err.response), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getClientList = createAsyncThunk(
  "pbi/getclientlist",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/getclientlist",
        "GET",
        null,
        thunkAPI
      );

      const data = await res.json();

      const { dispatch } = thunkAPI;
      if (res.status === 200) {
        return thunkAPI.fulfillWithValue(data);
      } else {
        dispatch(logout());
        toast.error("Unable to retrieve pbi client list", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getDataRefresh = createAsyncThunk(
  "pbi/getdatasetrefresh",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/getdatasetrefresh",
        "GET",
        null,
        thunkAPI
      );

      const data = await res.json();
      const { dispatch } = thunkAPI;
      if (res.status === 200) {
        dispatch(getDataRefresh());
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getReportPages = createAsyncThunk(
  "pbi/getreportpages",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/getreportpages",
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.reports;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logReportSelect = createAsyncThunk(
  "api/logs/report/selected",
  async (report_id, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        `api/logs/report/select?report_id=${report_id}`,
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getReportStatusList = createAsyncThunk(
  "pbi/getreportstatus",
  async (group_id, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        `pbi/getreportstatus/${group_id}`,
        "GET",
        null,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const putReportStatusUpdate = createAsyncThunk(
  "pbi/putreportstatus",
  async (report_list, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/putreportstatus/",
        "PUT",
        report_list,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        toast.success("Report Status Updated", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return data;
      } else {
        toast.error("Unable to update Report Statusses", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getEmbedInfo = createAsyncThunk(
  "api/getembedinfo",

  async (reportList, thunkAPI) => {

    var report_list = Object.keys(reportList).map((i) => ({ id: i }));
    var dataset_list = Object.keys(reportList).map((i) => ({
      id: reportList[i].datasetId,
    }));
    const body = { datasets: dataset_list, reports: report_list }
    try {
      const res = await makeAuthenticatedApiCall(
        "pbi/getembedinfo/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      const { dispatch } = thunkAPI;
      if (res.status === 200) {
        dispatch(
          setReportConfig({
            accessToken: data.token,
          })
        );
        return data;
      } else {
        toast.error("Unable to retrieve embed access token", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(logout());
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      const { dispatch } = thunkAPI;
      toast.error("Unable to retrieve embed access token", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(logout());

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  pbiThemeloading: false,
  embedInfoloading: true,
  reportListLoading: true,
  reportPagesLoading: true,
  userReportListLoading: false,
  userReportList: null,
  userDataRefresh: null,
  reportPages: null,
  pbiClients: [],
  pbiClientsLoading: true,

  pbiReportStatusList: [],
  pbiReportStatusListLoading: false,
  pbiReportStatusUpdateLoading: true,
  embedInfo: null,
  reportList: null,
  pbiTheme: null,
  report_id: null,
  page_name: "",

};

const pbiSlice = createSlice({
  name: "pbi",
  initialState,
  reducers: {
    selectReport: (state, action) => {
      state.report_id = action.payload
    },
    selectReportPage: (state, action) => {
      state.page_name = action.payload
    },
    setLoadingPages: (state, action) => {
      state.reportListLoading = action.payload
    },

    resetPbiState: () => initialState,
    updateReportStatus: (state, action) => {
      const { report_id } = action.payload;
      const updatedState = state.pbiReportStatusList.map((obj) => {
        if (obj.report_id === report_id) {
          return {
            ...obj,
            is_active: obj.is_active == 1 ? 0 : 1,
          };
        }
        return obj;
      });

      return {
        ...state,
        pbiReportStatusList: updatedState,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPbiTheme.pending, (state) => {
        state.pbiThemeloading = true;
      })
      .addCase(getPbiTheme.fulfilled, (state, action) => {
        state.pbiThemeloading = false;
        state.pbiTheme = action.payload;
      })
      .addCase(getPbiTheme.rejected, (state) => {
        state.pbiThemeloading = false;
      })
      .addCase(getUserReportList.pending, (state) => {
        state.userReportListLoading = true;
      })
      .addCase(getUserReportList.fulfilled, (state, action) => {
        state.userReportListLoading = false;
        state.userReportList = action.payload;
      })
      .addCase(getUserReportList.rejected, (state) => {
        state.userReportListLoading = false;
      })

      .addCase(getReportList.pending, (state) => {
        state.reportListLoading = true;
      })
      .addCase(getReportList.fulfilled, (state, action) => {
        state.reportListLoading = false;
        state.reportList = action.payload;
      })
      .addCase(getReportList.rejected, (state) => {
        state.reportListLoading = false;
      })
      .addCase(getClientList.pending, (state) => {
        state.pbiClientsLoading = true;
      })
      .addCase(getClientList.fulfilled, (state, action) => {
        state.pbiClientsLoading = false;
        state.pbiClients = action.payload;
      })
      .addCase(getClientList.rejected, (state) => {
        state.pbiClientsLoading = false;
      })
      .addCase(getReportStatusList.pending, (state) => {
        state.pbiReportStatusListLoading = true;
      })
      .addCase(getReportStatusList.fulfilled, (state, action) => {
        state.pbiReportStatusListLoading = false;
        state.pbiReportStatusList = action.payload;
      })
      .addCase(getReportStatusList.rejected, (state) => {
        state.pbiReportStatusListLoading = true;
      })

      .addCase(putReportStatusUpdate.pending, (state) => {
        state.pbiReportStatusUpdateLoading = true;
      })
      .addCase(putReportStatusUpdate.fulfilled, (state) => {
        state.pbiReportStatusUpdateLoading = false;
      })
      .addCase(putReportStatusUpdate.rejected, (state) => {
        state.pbiReportStatusUpdateLoading = true;
      })

      .addCase(getDataRefresh.fulfilled, (state, action) => {
        state.userDataRefresh = action.payload;
      })
      .addCase(getReportPages.pending, (state) => {
        state.reportPagesLoading = true;
      })
      .addCase(getReportPages.fulfilled, (state, action) => {
        state.reportPagesLoading = false;
        state.reportPages = action.payload;
      })
      .addCase(getReportPages.rejected, (state) => {
        state.reportPagesLoading = false;
      })
      .addCase(getEmbedInfo.pending, (state) => {
        state.embedInfoloading = true;
      })
      .addCase(getEmbedInfo.fulfilled, (state, action) => {
        state.embedInfoloading = false;
        state.embedInfo = action.payload;
      })
      .addCase(getEmbedInfo.rejected, (state) => {
        state.embedInfoloading = false;
      });
  },
});

export const { updateReportStatus, resetPbiState, selectReport, selectReportPage, setLoadingPages } = pbiSlice.actions;
export default pbiSlice.reducer;
