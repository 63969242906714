import { useRef, useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/images/logo/HUBL_Default_Logo.png";
import NavbarMain from "../../../website/Layout/Navbar.js";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { register } from "../../../slice/user";
import { useNavigate } from "react-router-dom";
import "./register.scss";
import Popup from "../../components/popup/Popup";
import Register from "../../../assets/Register.png";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{6,24}$/;

const PWD_UPPER = new RegExp("((?=.*[A-Z]))");
const PWD_LOWER = new RegExp("((?=.*[a-z]))");
const PWD_NUMBER = new RegExp("((?=.*[0-9]))");
const PWD_SPECIAL = new RegExp("((?=.*[@$!%*#?&]))");
const PWD_LEN = new RegExp("([A-Za-zd@$!#%*?&]{6,})");
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;

const formInput = {
  borderRadius: "10px",
  borderColor: "#e3e3e3",
};

const buttonObj = {
  height: "50px",
  width: "250px",
  marginTop: "30px",
  marginLeft: "3px",
  paddingLeft: "15px",
  paddingRight: "15px",
  color: "#ffffff",
  backgroundColor: "#ec5210",
  borderColor: "#ec5210",
  borderRadius: "20px",
};

const RegisterPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    matchPwd: "",
  });
  const { first_name, last_name, email, password, matchPwd } = formData;
  const userRef = useRef();
  const [popHeader, setPopHeader] = useState("");
  const [popContent, setPopContent] = useState("");
  const [open, setOpen] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (!PWD_LEN.test(password)) {
      setErrMsg("Password must be at least 6 characters long");
    } else if (!PWD_LOWER.test(password)) {
      setErrMsg("Password must contain a lowercase letter");
    } else if (!PWD_UPPER.test(password)) {
      setErrMsg("Password must contain an uppercase letter");
    } else if (!PWD_SPECIAL.test(password)) {
      setErrMsg("Password must contain a special character");
    } else if (!PWD_NUMBER.test(password)) {
      setErrMsg("Password must contain a number");
    }

    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validEmail) {
      setOpen(true);
      setPopContent("Please enter valid email");
    } else if (!validPwd) {
      setOpen(true);
      setPopContent(errMsg);
    } else if (!validMatch) {
      setOpen(true);
      setPopContent("Passwords are not matching");
    }

    if (validEmail && validPwd && validMatch) {
      dispatch(register(formData)).then((value) => {
        if (value.meta.requestStatus == "fulfilled") {
          navigate("/hubl");
        } else {
          setOpen(true);
          switch (value.error.message) {
            case "Rejected":
              setPopHeader("Register Failed");
              if (value.payload.email) {
                setPopContent(value.payload.email[0]);
              } else if (value.payload.password) {
                setPopContent(value.payload.password[0]);
              } else {
                setPopContent(value.error.message);
              }

            default:
          }
        }
      });
    } else {
      setOpen(true);
      setPopContent(errMsg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Analytics | Register</title>
        <meta name="description" content={"Analytics Portal"} />
      </Helmet>
      <div className="register">
        <NavbarMain />
        <Popup
          open={open}
          header={popHeader}
          content={popContent}
          onClose={handleClose}
          image={Register}
        />
        <Container
          className="registerContainer"
          style={{
            marginTop: "auto",
            width: "500px",
            padding: "50px",
          }}
        >
          <Row className="rowWidth">
            <Col>
              <Form>
                <div className="heading-register">
                  <img
                    className="logo-register"
                    src={Logo}
                    alt="register logo"
                  />
                </div>
                <h3 className="heading-title">Register for an Account</h3>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name*"
                    style={formInput}
                    ref={userRef}
                    name="first_name"
                    value={first_name}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </Form.Group>
                <p
                  id="firstnamenote"
                  className={!first_name ? "instructions" : "offscreen"}
                >
                  Please enter first name
                </p>
                <Form.Group className="mb-3" controlId="formBasicSurname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name*"
                    style={formInput}
                    name="last_name"
                    value={last_name}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </Form.Group>
                <p
                  id="lastnamenote"
                  className={!last_name ? "instructions" : "offscreen"}
                >
                  Please enter last name
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email*"
                    style={formInput}
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                    aria-invalid={validEmail ? "false" : "true"}
                    aria-describedby="emailnote"
                  />
                </Form.Group>
                <p
                  id="uidnote"
                  className={!validEmail ? "instructions" : "offscreen"}
                >
                  Must be valid email
                </p>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password*"
                    style={formInput}
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                  />
                </Form.Group>
                <p
                  id="pwdnote"
                  className={!validPwd ? "instructions" : "offscreen"}
                >
                  6 to 24 characters.
                  <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character.
                  <br />
                  Allowed special characters:{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>

                <Form.Group className="mb-3" controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password*"
                    style={formInput}
                    name="matchPwd"
                    value={matchPwd}
                    onChange={(e) => onChange(e)}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                  />
                  <p
                    id="confirmnote"
                    className={!validMatch ? "instructions" : "offscreen"}
                  >
                    Must match the first password input field.
                  </p>
                </Form.Group>

                <div className="d-grid gap-2 button-container">
                  <Button
                    // variant="dark"
                    type="submit"
                    size="lg"
                    style={buttonObj}
                    onClick={(e) => onSubmit(e)}
                    href={"/"}
                  >
                    Register
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RegisterPage;
