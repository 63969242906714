import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
  postWorkRequest,
} from "slice/admin";
import TextFieldsComponent from "../../components/textfield/TextField";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./createLogRequest.scss"; // Import the CSS file
import { selectMenuItem } from '../../../slice/menuSection'
import { TICKET_OVERVIEW } from "../../utils/types";

function PostLogRequestComponent() {
  const [selectedCompany, setCompany] = useState(null);
  const [selectedPriority, setPriority] = useState(null);
  const [selectedCategory, setCategory] = useState(null);
  const [selectedProduct, setProduct] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [invalidFields, setInvalidFields] = useState({}); // State to track invalid fields
  const [emailError, setEmailError] = useState(""); // State to track email error message
  const [fileError, setFileError] = useState(""); // State to track file error message

  const { user } = useSelector((state) => state.userInfo);
  const customer_id = user === null ? 0 : user.customer_id;

  const [formData, setFormData] = useState({
    customer: user ? user.customer_name : '',
    customer_id: customer_id,
    company: "",
    subject: "",
    priority: "",
    category: "",
    product: "",
    person: user ? user.first_name + ' ' + user.last_name : '',
    contact: user ? user.email : '',
    reference: "",
    problem: "",
    attachments: [],
  });

  const companyList = [
    { label: "ATOM DTS", value: "Atom" },
    { label: "Prophet SA", value: "Prophet" }
  ];

  function getPriority(company) {
    const priorityList = [];
    if (!company) return;
    if (company.value === 'Atom') {
      priorityList.push(
        { label: "Mission Critical (max 6hrs)", value: "Mission Critical (max 6hrs)" },
        { label: "High (max 2 days)", value: "High (max 2 days)" },
        { label: "Medium (max 5 days)", value: "Medium (max 5 days)" },
        { label: "Low (min 2 weeks)", value: "Low (min 2 weeks)" },
        { label: "Long-term (4 to 6 weeks)", value: "Long-term (4 to 6 weeks)" }
      );
    } else {
      priorityList.push(
        { label: "Showstopper - now", value: "50" },
        //{ label: "Within six hours", value: "53" },
        { label: "Within two days (48 hours)", value: "55" },
        { label: "Within five days (120 hours)", value: "58" },
        { label: "Within 2 weeks", value: "60" },
        { label: "Within 6 weeks", value: "62" }
      );
    }
    return priorityList;
  }

  // const priorityList = [
  //   { label: "Mission Critical (max 6hrs)", value: "Mission Critical" },
  //   { label: "High (max 2 days)", value: "High" },
  //   { label: "Medium (max 5 days)", value: "Medium" },
  //   { label: "Low (min 2 weeks)", value: "Low" },
  //   { label: "Long-term (4 to 6 weeks)", value: "Long-term" },
  // ];

  function getCategoryList(company) {
    const categoryList = [];
    if (!company) return;
    if (company.value === 'Atom') {
      categoryList.push({ label: "Support", value: "support" });
    } else {
      categoryList.push(
        { label: "Bug", value: "bug" },
        { label: "Implementation Task", value: "implementationtask" },
        { label: "Modification Request", value: "mod" },
        { label: "Query", value: "query" }
      );
    }
    return categoryList;
  }

  const goBack = () => {
    dispatch(selectMenuItem(TICKET_OVERVIEW));
  }

  const productList = [
    { label: "QX", value: "QX" },
    { label: "Pr3", value: "Pr3" },
    { label: "HUBL", value: "Custom Apps" },
  ];

  const dispatch = useDispatch();

  useEffect(() => { }, [dispatch, selectedCompany]);
  useEffect(() => { }, [dispatch, selectedPriority]);
  useEffect(() => { }, [dispatch, selectedCategory]);
  useEffect(() => { }, [dispatch, selectedProduct]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    switch (name) {
      case "company":
        setCompany(value);
        break;
      case "priority":
        setPriority(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "product":
        setProduct(value);
        break;
      default:
        break;
    }
  };

  const handleUploadChange = (event) => {
    const currentFile = event.target.files;
    const restrictedFileTypes = ['.exe', '.bat'];
    const maxFileSize = 15 * 1024 * 1024; // 15MB

    let totalSize = formData.attachments.reduce((acc, file) => acc + file.size, 0);
    let newAttachment = [...formData.attachments];

    for (const file of currentFile) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (restrictedFileTypes.includes(`.${fileExtension}`)) {
        setFileError(`File type '.${fileExtension}' is not allowed.`);
        return;
      }
      if (totalSize + file.size > maxFileSize) {
        setFileError("Total file size exceeds 15MB.");
        return;
      }
      if (newAttachment.some(att => att.name === file.name && att.size === file.size)) {
        setFileError(`File '${file.name}' is already uploaded.`);
        return;
      }
      newAttachment.push(file);
      totalSize += file.size;
    }

    setFileError("");
    setFormData({ ...formData, attachments: newAttachment });
    event.target.value = '';
  };

  const handleRemoveFile = (index) => {
    const newAttachment = [...formData.attachments];
    newAttachment.splice(index, 1);
    setFormData({ ...formData, attachments: newAttachment });
  };

  const handleChangeAutoComplete = (event, values, name) => {
    if (values) {
      setFormData({ ...formData, [name]: values.value });
    } else {
      setFormData({ ...formData, [name]: "" });
    }

    switch (name) {
      case "company":
        setCompany(values);
        //when we change company, we need to reset the the Priority, Category and Product
        setPriority(null);
        setCategory(null);
        setProduct(null);
        break;
      case "priority":
        setPriority(values);
        break;
      case "category":
        setCategory(values);
        break;
      case "product":
        setProduct(values);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const newInvalidFields = {};
    if (!formData.company) newInvalidFields.company = true;
    if (!formData.priority) newInvalidFields.priority = true;
    if (!formData.category) newInvalidFields.category = true;
    if (!formData.product) newInvalidFields.product = true;
    const emailAddresses = formData.contact.split(/[;,]+/).map(email => email.trim()).filter(email => email !== "");
    const invalidEmails = emailAddresses.filter(email => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      newInvalidFields.contact = true;
      setEmailError("Please enter valid email addresses.");
    } else {
      setEmailError("");
    }
    if (formData.company === 'Atom' && !formData.subject) newInvalidFields.subject = true;
    if (!formData.problem) newInvalidFields.problem = true;

    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      alert("Please ensure all fields are captured before continuing.");
      return; // Stop execution if validation fails
    }

    setInvalidFields({});
    setIsFetching(true);
    dispatch(postWorkRequest(formData)).then((e) => {
      setIsFetching(false);
      if (!e.error) {
        setFormData({
          customer: user ? user.customer_name : '',
          customer_id: customer_id,
          company: "",
          subject: "",
          priority: "",
          category: "",
          product: "",
          person: user ? user.first_name + ' ' + user.last_name : '',
          contact: user ? user.email : '',
          reference: "",
          problem: "",
          attachments: [],
        });

        setCompany(null);
        setPriority(null);
        setCategory(null);
        setProduct(null);

        window.scrollTo({ top: 0, behavior: "smooth" });

        goBack(); // Navigate to TicketOverview.js
      }
    });
  };

  const uploadedFiles = [];
  Array.from(formData.attachments).forEach(file => {
    uploadedFiles.push(
      <p key={file.name}>
        - <a href={URL.createObjectURL(file)} download={file.name} title={file.name}>{file.name}</a>
        <IconButton edge="end" aria-label="download" href={URL.createObjectURL(file)} download={file.name}>
          <DownloadIcon />
        </IconButton>
      </p>
    );
  });

  if (isFetching) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <AutocompleteComponent
          title="Company *"
          name="company"
          options={companyList}
          value={selectedCompany}
          isMultiple={false}
          onChange={handleChangeAutoComplete}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={invalidFields.company ? 'invalid' : ''}
        />

        {selectedCompany && selectedCompany.value === 'Atom' ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth={true}>
              <TextFieldsComponent
                title="Subject *"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleInputChange}
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className={invalidFields.subject ? 'invalid' : ''}
              />
            </FormControl>
          </Grid>
        ) : (<></>)}

        <Grid item xs={6} md={3}>
          <AutocompleteComponent
            title="Category *"
            name="category"
            options={getCategoryList(selectedCompany)}
            value={selectedCategory}
            isMultiple={false}
            onChange={handleChangeAutoComplete}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={invalidFields.category ? 'invalid' : ''}
          />
        </Grid>
        {selectedCategory && selectedCategory.value === 'support' ? (
          <Grid item xs={6} md={3} container alignItems="center">
            <div className="text-label" style={{ textAlign: 'center' }}>
              *Please note that any hours spent on a support request will deplete your retainer(if you have one) otherwise be quoted for</div>{" "}
          </Grid>
        ) : (<></>)}


        <AutocompleteComponent
          title="Priority *"
          name="priority"
          options={getPriority(selectedCompany)}
          value={selectedPriority}
          isMultiple={false}
          onChange={handleChangeAutoComplete}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={invalidFields.priority ? 'invalid' : ''}
        />

        <AutocompleteComponent
          title="Product *"
          name="product"
          options={productList}
          value={selectedProduct}
          isMultiple={false}
          onChange={handleChangeAutoComplete}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={invalidFields.product ? 'invalid' : ''}
        />

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth={true}>
            <TextFieldsComponent
              title="Person"
              name="person"
              placeholder="Person"
              value={formData.person}
              onChange={handleInputChange}
              xs={3}
              sm={3}
              md={3}
              lg={3}
              disabled={true}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth={true}>
            <TextFieldsComponent
              title="Site Contact *"
              name="contact"
              placeholder="Site Contact"
              value={formData.contact}
              onChange={handleInputChange}
              maxChars={150}
              type="email"
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className={invalidFields.contact ? 'invalid' : ''}
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth={true}>
            <TextFieldsComponent
              title="Existing Log/Ticket Reference"
              name="reference"
              placeholder="Reference"
              value={formData.reference}
              onChange={handleInputChange}
              xs={3}
              sm={3}
              md={3}
              lg={3}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="text-label">Upload Attachments</div>{" "}

          <List>
            {formData.attachments.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton edge="end" aria-label="download" href={URL.createObjectURL(file)} download={file.name}>
                  <DownloadIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>

          <label htmlFor="myfileselector" style={{
            backgroundColor: '#3b3b42',
            fontFamily: 'Lato,sans-serif',
            color: 'white',
            padding: '6px 16px',
            cursor: 'pointer',
            fontSize: '0.75rem',
            fontWeight: '500',
            borderRadius: '4px',
            lineHeight: '1.75',
            display: 'inline-block'
          }}> CHOOSE FILES
            <input
              type="file"
              onChange={(e) => handleUploadChange(e)}
              multiple
              id="myfileselector"
              inputid="file-upload"
              style={{
                display: 'None',
              }}
            />
          </label>
          {fileError && <div className="error-message">{fileError}</div>}
        </Grid>

        <TextFieldsComponent
          title="Problem *"
          name="problem"
          placeholder="Provide as much detail as possible"
          value={formData.problem}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          multiline={true}
          maxChars={65535}
          className={invalidFields.problem ? 'invalid' : ''}
        />

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </Button>
          {" "}
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={goBack} // Navigate to TicketOverview.js
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

    </div>
  );
}

export default PostLogRequestComponent;