import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { selectMenuItem } from '../../../slice/menuSection'
import Header from "../../components/charts/Header";
import Grid from "@mui/material/Grid";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import { BulletList } from "react-content-loader";
import TicketAccordion from "../../components/accordians/TicketAccordian";
import "./tickets.scss";
import {
  DEPARTMENT_NAME,
  PROJECT_NAME,
  TICKET_STATUS,
  TICKET_PRIORITY,
  TICKET_NUMBER,
  TAXONOMY,
  IS_OVERDUE,
  TICKET_OVERVIEW,
  PROPHET_LOGS,
  ATOM_TICKETS,
  PROJECT_TICKETS,
  ZOHO_TICKETS,
  PRIORITY_NAME,
  ORGINIZATION_NAME,
  WORK_REQUEST,
} from "../../utils/types";
import TicketOverviewDashboard from "../recharts/TicketOverview";
import PostLogRequestComponent from "./CreateLogRequest";
import { fetchTicketComments } from "slice/commentSlice";
import { calculateDaysBetween } from "hubl/utils/helpers";
import SwitchButtonGroup from "hubl/components/switch/Toggle";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "../../theme";
import { tokens } from "../../theme";
import {
  PROPHET_ACCOUNT_NAMES,
  ATOM_ACCOUNT_NAMES,
  CLIENT_STATUSSES,
} from "hubl/utils/config";
import { fetchFlaggedTickets } from "slice/ticketSlice";

const priorityOrder = {
  "Mission Critical (max 6 hrs)": 1,
  "High (max 2 days)": 2,
  "Within five days (120 hours)": 3,
  "Medium (max 5 days)": 4,
  Medium: 5,
  "Within 2 weeks": 6,
  "Low (min 2 weeks)": 7,
  "Long-term (4 to 6 weeks)": 8,
  "Within 6 weeks": 9,
  "Modification - P1": 10,
  "Modification - P2": 11,
  "Modification - P3": 12,
  "Modification - P4": 13,
  "Partner Dependent": 14,
};

const atom_account_names = [
  "ATOM DTS Services",
  "Atom DTS Implementations",
  "ATOM DTS Data Solutions",
  "ATOM DTS Technology Solutions",
  "ATOM DTS Quick Tickets",
];

const colArray = [];
for (let i = 0; i < 2; i++) {
  colArray.push(
    <Grid item xs={12} key={i}>
      <BulletList />
    </Grid>
  );
}

const TicketComponent = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);
  const { flaggedTickets } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();
  const { section } = useSelector((state) => state.selectedMenuSection);
  const { tickets, ticketsLoading } = useSelector((state) => state.userInfo);
  const [allTickets, setAllTickets] = useState([]);
  const [shownTickets, setShownTickets] = useState([]);
  const [sortedTicketsOnFlag, setSortedTicketsOnFlag] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [comparison, setComparison] = useState("Due_Date");

  const [filterOptions, setFilterOptions] = useState({
    department: [],
    organization: [],
    category: [],
    number: [],
    priority: [],
    status: [],
    taxonomy: [],
    overdue: [],
  });

  function filterLogsByDepartment(sectionTickets) {
    const prophet_account_names = ["Prophet"];
    const prophet_tickets = sectionTickets.filter((ticket) =>
      prophet_account_names.includes(ticket.Department_Name)
    );
    switch (section) {
      case PROPHET_LOGS:
        return prophet_tickets;

      case ATOM_TICKETS:
        const atom_tickets = sectionTickets.filter((ticket) =>
          atom_account_names.includes(ticket.Department_Name)
        );
        return atom_tickets;

      case PROJECT_TICKETS:
        return sectionTickets.filter(
          (ticket) =>
            ticket.Project_Name !== undefined && ticket.Project_Name !== ""
        );
      case TICKET_OVERVIEW:
        return sectionTickets;
      case ZOHO_TICKETS:
        return sectionTickets;
      default:
        return prophet_tickets;
    }
  }

  const processData = (data, comparison) => {
    const processedData = data.map((ticket) => {
      const today = new Date().toISOString();
      const daysOverdue = calculateDaysBetween(ticket[comparison], today);

      let daysOverdueCategory;
      if (daysOverdue >= 1 && daysOverdue <= 7) {
        daysOverdueCategory = "1-7 days";
      } else if (daysOverdue >= 8 && daysOverdue <= 14) {
        daysOverdueCategory = "8-14 days";
      } else if (daysOverdue >= 15 && daysOverdue <= 21) {
        daysOverdueCategory = "15-21 days";
      } else if (daysOverdue >= 22 && daysOverdue <= 30) {
        daysOverdueCategory = "22-30 days";
      } else if (daysOverdue > 30) {
        daysOverdueCategory = "30+ days";
      } else {
        daysOverdueCategory = "Not Overdue";
      }

      let organization;
      if (CLIENT_STATUSSES.includes(ticket.Log_or_Ticket_Status)) {
        organization = "Customer";
      } else if (ATOM_ACCOUNT_NAMES.includes(ticket.Department_Name)) {
        organization = "Atom DTS";
      } else if (PROPHET_ACCOUNT_NAMES.includes(ticket.Department_Name)) {
        organization = "Prophet";
      } else {
        organization = "None";
      }

      return {
        ...ticket, // Preserve all old properties
        daysOverdue,
        daysOverdueCategory,
        organization,
      };
    });

    return processedData;
  };

  const getFilteredTickets = (temp_tickets) => {
    let sectionTickets = filterLogsByDepartment(temp_tickets);
    let filteredTickets = filterSelectObject(sectionTickets, filterOptions);
    setShownTickets(filteredTickets);
  };
  useEffect(() => {
    dispatch(fetchFlaggedTickets());
  }, []);

  useEffect(() => {
    if (tickets) {
      const processedTickets = processData(tickets, comparison);
      setAllTickets(processedTickets);
      getFilteredTickets(processedTickets);
    }
  }, [tickets, comparison, section]);

  const isFlagged = (ticketNumber) => {
    return flaggedTickets.some(
      (ticket) => ticket.ticket_number === ticketNumber
    );
  };

  useEffect(() => {
    if (flaggedTickets && shownTickets) {
      // Helper function to check if a ticket number exists in flaggedTickets

      // Sort sortedTickets based on the presence of Log_or_Ticket_Number in flaggedTickets
      const sortedBasedOnFlag = [...shownTickets].sort((a, b) => {
        const aIsFlagged = isFlagged(a.Log_or_Ticket_Number);
        const bIsFlagged = isFlagged(b.Log_or_Ticket_Number);

        // If a is flagged and b is not, a comes first
        if (aIsFlagged && !bIsFlagged) return -1;
        // If b is flagged and a is not, b comes first
        if (bIsFlagged && !aIsFlagged) return 1;
        // If both or neither are flagged, keep their original order
        return 0;
      });
      setSortedTicketsOnFlag(sortedBasedOnFlag);
    } else if (shownTickets) {
      setSortedTicketsOnFlag(shownTickets);
    } else {
      setSortedTicketsOnFlag([]);
    }
  }, [flaggedTickets, shownTickets]);

  useEffect(() => {
    if (allTickets) {
      let sectionTickets = filterLogsByDepartment(allTickets);
      let filteredTickets = filterSelectObject(sectionTickets, filterOptions);
      setShownTickets(filteredTickets);
    }
  }, [filterOptions]);

  function getUniqueValues(name, keyName, sort_dict = null) {
    const tempFilterOptions = {
      ...filterOptions,
      [name]: [],
    };

    if (allTickets) {
      let sectionTickets = filterLogsByDepartment(allTickets);
      let dataFiltered = filterSelectObject(sectionTickets, tempFilterOptions);
      const uniqueValues = [
        ...new Set(dataFiltered.map((item) => item[keyName])),
      ];
      const uniqueValuesList = uniqueValues.map((value) => ({
        label: value,
        value: value,
      }));
      let sortedUniqueValuesList = [];
      if (sort_dict) {
        sortedUniqueValuesList = uniqueValuesList.sort((a, b) => {
          return sort_dict[a.value] - sort_dict[b.value];
        });
      } else {
        sortedUniqueValuesList = uniqueValuesList.sort(function (a, b) {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
      }
      return sortedUniqueValuesList;

      // const sortedUniqueValuesList = uniqueValuesList.sort();
    } else {
      return [];
    }
  }

  const filterSelectObject = (ticket_object, filter_options) => {
    let filteredTickets = ticket_object.filter((data) => {
      let match = true;
      if (filter_options.organization.length > 0) {
        match =
          match &&
          filter_options.organization.includes(data[ORGINIZATION_NAME]);
      }
      if (filter_options.category.length > 0) {
        match = match && filter_options.category.includes(data[PRIORITY_NAME]);
      }
      if (filter_options.status.length > 0) {
        match = match && filter_options.status.includes(data[TICKET_STATUS]);
      }
      if (filter_options.priority.length > 0) {
        match =
          match && filter_options.priority.includes(data[TICKET_PRIORITY]);
      }
      if (filter_options.number.length > 0) {
        match = match && filter_options.number.includes(data[TICKET_NUMBER]);
      }
      if (filter_options.taxonomy.length > 0) {
        match = match && filter_options.taxonomy.includes(data[TAXONOMY]);
      }
      if (filter_options.overdue.length > 0) {
        match = match && filter_options.overdue.includes(data[IS_OVERDUE]);
      }
      if (filter_options.department.length > 0) {
        match =
          match && filter_options.department.includes(data[DEPARTMENT_NAME]);
      }
      return match;
    });

    return filteredTickets;
  };

  const handleChangeAutoComplete = (event, values, name) => {
    const outputValues = values.map((obj) => obj.value);
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: outputValues,
    }));
  };
  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(fetchTicketComments({ comment_id: `ticket_${panel}` }));
  };

  const selectProps = [
    {
      title: "Organization",
      name: "organization",
      options: getUniqueValues("organization", ORGINIZATION_NAME),
      isMultiple: true,
      value: filterOptions.organization.map((value) => ({
        label: value,
        value: value,
      })),
    },

    {
      title: "Log / Ticket Priority",
      name: "priority",
      options: getUniqueValues("priority", TICKET_PRIORITY, priorityOrder),
      isMultiple: true,
      value: filterOptions.priority.map((value) => ({
        label: value,
        value: value,
      })),
    },
    {
      title: "Log / Ticket Overdue Category",
      name: "category",
      options: getUniqueValues("category", PRIORITY_NAME),
      isMultiple: true,
      value: filterOptions.category.map((value) => ({
        label: value,
        value: value,
      })),
    },

    {
      title: "Overdue",
      name: "overdue",
      options: getUniqueValues("overdue", IS_OVERDUE),
      isMultiple: true,
      value: filterOptions.overdue.map((value) => ({
        label: value,
        value: value,
      })),
    },
    {
      title: "Log / Ticket Status",
      name: "status",
      options: getUniqueValues("status", TICKET_STATUS),
      isMultiple: true,
      value: filterOptions.status.map((value) => ({
        label: value,
        value: value,
      })),
    },
    {
      title: "Log / Ticket Number",
      name: "number",
      options: getUniqueValues("number", TICKET_NUMBER),
      isMultiple: true,
      value: filterOptions.number.map((value) => ({
        label: value,
        value: value,
      })),
    },
  ];

  // TOGGLE BUTTON 0:1
  const toggles = [
    { value: "Due_Date", label: "Due Date" },
    { value: "Date_Created", label: "Date Created" },
  ];

  const handleCreateWorkRequestClick = () => {
    dispatch(selectMenuItem(WORK_REQUEST));
  };

  const handleBackClick = () => {
    dispatch(selectMenuItem(TICKET_OVERVIEW));
  };

  return (
    <div className="ticket-container">
      {!ticketsLoading && shownTickets ? (
        <>
          <Grid container spacing={2} sx={{ pb: "16px" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={10}>
                  <Header title={`${section}`} />
                </Grid>
                {section === WORK_REQUEST ? (
                  <Grid item xs={6} md={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleBackClick} // Navigate to TicketOverview.js
                    >
                      Back
                    </Button>{" "}
                  </Grid>) : <></>}
                {section === TICKET_OVERVIEW ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ pb: "12px" }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleCreateWorkRequestClick}
                    >
                      Create Work Request
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ pb: "2px" }}
                    ></Grid>
                  </>
                )}
              </Grid>

              {section !== WORK_REQUEST ? (
                <Grid container spacing={2}>
                  {selectProps.map((props) => (
                    <Grid
                      key={props.name}
                      item
                      xs={12}
                      sm={"auto"}
                      md={"auto"}
                      lg={"auto"}
                    >
                      <AutocompleteComponent
                        {...props}
                        onChange={handleChangeAutoComplete}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <>
            {section === TICKET_OVERVIEW ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SwitchButtonGroup
                    toggles={toggles}
                    value={comparison}
                    onChange={(event, newComparison) =>
                      setComparison(newComparison)
                    }
                    colors={colors}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TicketOverviewDashboard
                    ticket_data={shownTickets}
                    comparison={comparison}
                    toggles={toggles}
                  />{" "}
                </Grid>
              </Grid>
            ) : section === WORK_REQUEST ? (
              <Grid item xs={12} sm={12}>
                <PostLogRequestComponent
                />
              </Grid>
            ) : (
              <div className="ticket-box">
                {sortedTicketsOnFlag.map((log) => (
                  <TicketAccordion
                    key={log.Log_or_Ticket_Number}
                    {...log}
                    handleChange={handleChangeAccordian}
                    expanded={expanded}
                  />
                ))}
              </div>
            )}
          </>
        </>
      ) : (
        <Grid container spacing={2}>
          {colArray}
        </Grid>
      )}
    </div>
  );
};

export default TicketComponent;