import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";

export const getUserDashboardList = createAsyncThunk(
  "recharts/user/dashboard/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/user/dashboard/list",
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.dashboard;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerStatus = createAsyncThunk(
  "recharts/oi/container/status",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/status/",
        "POST",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerRisk = createAsyncThunk(
  "recharts/oi/container/risk",
  async (form_data, thunkAPI) => {
    const body = {
      dimension_columns:
        "pod_timeofarrival_warning_risk,pod_timeofarrival_warning_risk_verbose",
      container: form_data.container,
      container_status: form_data.container_status,
      tags: form_data.tags,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/risk/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerRiskPOD = createAsyncThunk(
  "recharts/oi/container/risk/pod",
  async (form_data, thunkAPI) => {
    const body = {
      dimension_columns:
        "pod_timeofarrival_warning_risk,pod_timeofarrival_warning_risk_verbose,pod_loc_locode",
      container: form_data.container,
      container_status: form_data.container_status,
      tags: form_data.tags,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/risk/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerList = createAsyncThunk(
  "recharts/oi/container/dimensions/containers",
  async (_, thunkAPI) => {
    const body = {
      dimension_columns: "id,container_number",
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/dimensions/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerStatusses = createAsyncThunk(
  "recharts/oi/container/dimensions/statusses",
  async (_, thunkAPI) => {
    const body = {
      dimension_columns:
        "pod_timeofarrival_warning_risk,pod_timeofarrival_warning_risk_verbose",
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/dimensions/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerMilestones = createAsyncThunk(
  "recharts/oi/milestones",
  async (container_id, thunkAPI) => {
    const body = {
      container_id: container_id,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/milestones/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerEvents = createAsyncThunk(
  "recharts/oi/container/events",
  async (container_id, thunkAPI) => {
    const body = {
      container_id: container_id,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/events/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContainerSummary = createAsyncThunk(
  "recharts/oi/container/table",
  async (form_data, thunkAPI) => {
    const body = {
      dimension_columns: "",
      container: form_data.container,
      container_status: form_data.container_status,
      tags: form_data.tags,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "recharts/oi/container/summary/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  userRechartDashboardsLoading: false,
  userRechartDashboards: [],
  oiContainerStatus: [],
  oiContainerStatusLoading: false,
  oiContainerRisk: [],
  oiContainerRiskLoading: false,
  oiContainerRiskPOD: [],
  oiContainerRiskPODLoading: false,
  oiContainerList: [],
  oiContainerListLoading: false,

  oiContainerMilestones: [],
  oiContainerMilestonesLoading: false,

  oiContainerEvents: [],
  oiContainerEventsLoading: false,

  oiContainerStatusses: [],
  oiContainerStatussesLoading: false,
  container_number: null,
  tableDensity: null,
  oiContainerSummary: [],
  oiContainerSummaryLoading: false,
};

const rechartsSlice = createSlice({
  name: "recharts",
  initialState,
  reducers: {
    selectContainerNumber: (state, action) => {
      state.container_number = action.payload;
    },
    setTableDensity: (state, action) => {
      state.tableDensity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDashboardList.pending, (state) => {
        state.userRechartDashboardsLoading = true;
      })
      .addCase(getUserDashboardList.fulfilled, (state, action) => {
        state.userRechartDashboardsLoading = false;
        state.userRechartDashboards = action.payload;
      })
      .addCase(getUserDashboardList.rejected, (state) => {
        state.userRechartDashboardsLoading = false;
      })
      .addCase(fetchContainerStatus.pending, (state) => {
        state.oiContainerStatusLoading = true;
      })
      .addCase(fetchContainerStatus.fulfilled, (state, action) => {
        state.oiContainerStatusLoading = false;
        state.oiContainerStatus = action.payload;
      })
      .addCase(fetchContainerStatus.rejected, (state) => {
        state.oiContainerStatusLoading = false;
      })
      .addCase(fetchContainerRisk.pending, (state) => {
        state.oiContainerRiskLoading = true;
      })
      .addCase(fetchContainerRisk.fulfilled, (state, action) => {
        state.oiContainerRiskLoading = false;
        state.oiContainerRisk = action.payload;
      })
      .addCase(fetchContainerRisk.rejected, (state) => {
        state.oiContainerRiskLoading = false;
      })
      .addCase(fetchContainerRiskPOD.pending, (state) => {
        state.oiContainerRiskPODLoading = true;
      })
      .addCase(fetchContainerRiskPOD.fulfilled, (state, action) => {
        state.oiContainerRiskPODLoading = false;
        state.oiContainerRiskPOD = action.payload;
      })
      .addCase(fetchContainerRiskPOD.rejected, (state) => {
        state.oiContainerRiskPODLoading = false;
      })
      .addCase(fetchContainerList.pending, (state) => {
        state.oiContainerListLoading = true;
      })
      .addCase(fetchContainerList.fulfilled, (state, action) => {
        state.oiContainerListLoading = false;
        state.oiContainerList = action.payload;
      })
      .addCase(fetchContainerList.rejected, (state) => {
        state.oiContainerListLoading = false;
      })

      .addCase(fetchContainerStatusses.pending, (state) => {
        state.oiContainerStatussesLoading = true;
      })
      .addCase(fetchContainerStatusses.fulfilled, (state, action) => {
        state.oiContainerStatussesLoading = false;
        state.oiContainerStatusses = action.payload;
      })
      .addCase(fetchContainerStatusses.rejected, (state) => {
        state.oiContainerStatussesLoading = false;
      })

      .addCase(fetchContainerEvents.pending, (state) => {
        state.oiContainerEventsLoading = true;
      })
      .addCase(fetchContainerEvents.fulfilled, (state, action) => {
        state.oiContainerEventsLoading = false;
        state.oiContainerEvents = action.payload;
      })
      .addCase(fetchContainerEvents.rejected, (state) => {
        state.oiContainerEventsLoading = false;
      })

      .addCase(fetchContainerMilestones.pending, (state) => {
        state.oiContainerMilestonesLoading = true;
      })
      .addCase(fetchContainerMilestones.fulfilled, (state, action) => {
        state.oiContainerMilestonesLoading = false;
        state.oiContainerMilestones = action.payload;
      })
      .addCase(fetchContainerMilestones.rejected, (state) => {
        state.oiContainerMilestonesLoading = false;
      })

      .addCase(fetchContainerSummary.pending, (state) => {
        state.oiContainerSummaryLoading = true;
      })
      .addCase(fetchContainerSummary.fulfilled, (state, action) => {
        state.oiContainerSummaryLoading = false;
        state.oiContainerSummary = action.payload;
      })
      .addCase(fetchContainerSummary.rejected, (state) => {
        state.oiContainerSummaryLoading = false;
      });
  },
});

export const { selectContainerNumber, setTableDensity } = rechartsSlice.actions;
export default rechartsSlice.reducer;
